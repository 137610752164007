<template>
  <!-- Container -->
  <div class="row mt-2">
    <div class="col-10 col-lg">
      <p class="text-muted m-b-0">
        <span class="time-text">
          <span>{{ logType.text }}</span>
          <i
            class="activity-icon"
            v-bind:class="`${logType.class}-icon ${logType.icon}`"
          ></i>
        </span>
        <b v-html="personelName" style="padding-right: 5px"></b>
        <span v-html="logTitle"> </span>
      </p>
    </div>
    <div class="col-12 col-md-2">
      <p class="text-muted time-text">
        <!--<span
            class="badge badge-type"
            v-bind:class="`badge-${logType.class}`"
            >{{ logType.text }}</span
          >-->
        <span>11.23.2019 14:55</span>
        <i class="fa fa-clock mt-2" v-bind:class="`${logType.class}-icon`"></i>
        24 dakika önce
      </p>
    </div>
  </div>
  <!-- ./Container -->
</template>

<script>
export default {
  name: "WeActivityLogRow",
  props: ["logType", "personelName", "logTitle", "rowData"],
};
</script>

<style>
.time-text {
  font-weight: 500;
}
.badge-type {
  display: unset !important;
  margin-right: 5px;
  font-size: 12px;
}
.time-text span {
  visibility: hidden;
  width: 140px;
  background-color: #dee2e6;
  color: #6c757d;
  font-size: 13px;
  text-align: center;
  border-radius: 6px;
  font-weight: 0;
  padding: 4px 3px;
  position: absolute;
  z-index: 1;
  top: -30px;
}
.time-text:hover span {
  visibility: visible;
}
</style>
