<template>
  <!-- Container -->
  <div class="card-body">
    <!-- activity log filter component -->
    <WeActivityLogFilter v-bind:filter="logSetting.filter" />
    <hr />
    <!-- activity log list start -->
    <div
      class="main-log row mt-3"
      v-for="(item, index) in logData.slice(
        logSetting.startIndex,
        logSetting.startIndex + logSetting.minRowCount
      )"
      v-bind:key="index"
      v-bind:index="index"
      v-on:click="showDetail(index)"
    >
      <!-- activity log row left icon component -->
      <div class="col-12 col-lg">
        <div class="log-row shadow-sm">
          <div class="row">
            <div class="col-12 col-lg">
              <div class="col">
                <!-- activity log row component -->
                <WeActivityLogRow
                  v-bind:logType="logType(item.logType)"
                  v-bind:personelName="item.personelName"
                  v-bind:logTitle="item.logTitle"
                  v-bind:key="index"
                />
                <!-- activity log row detail component -->
                <WeActivityLogDetail
                  v-bind:logDetail="item.logDetail"
                  v-bind:index="index"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- activity log list finish -->
    <!-- activity log pagination-->
    <WeActivityLogFooter
      v-bind:rowCount="logData.length"
      v-bind:minRowCount="logSetting.minRowCount"
      v-on:changePage="changePage"
      v-on:onChangeMinRow="onChangeMinRow"
    />
    <!---->
  </div>
  <!-- ./Container -->
</template>

<script>
import WeActivityLogRow from "./components/Row/Index.vue";
import WeActivityLogDetail from "./components/Detail/Index.vue";
import WeActivityLogFilter from "./components/Filter/Index.vue";
import WeActivityLogFooter from "./components/Footer/Index.vue";
export default {
  name: "WeActivityLog",
  components: {
    WeActivityLogRow,
    WeActivityLogDetail,
    WeActivityLogFilter,
    WeActivityLogFooter,
  },
  props: ["logData", "logSetting"],
  methods: {
    logType(type) {
      switch (type) {
        case 1:
          return { class: "danger", text: "Silme", icon: "fa fa-window-close" };
        case 2:
          return { class: "warning", text: "Güncelleme", icon: "fa fa-cog" };
        case 3:
          return {
            class: "success",
            text: "Ekleme",
            icon: "fa fa-plus-circle",
          };
      }
    },
    showDetail(index) {
      let detailDiv = document.querySelector(`.activity-log-${index}`);
      if (detailDiv) {
        detailDiv.style.display = detailDiv.style.display == "" ? "block" : "";
      }
    },
    //page number click
    changePage(activePageId) {
      this.logSetting.startIndex =
        this.logSetting.minRowCount * (activePageId - 1);
    },
    //selected show row count
    onChangeMinRow(value) {
      if (value > this.logData.length) {
        value = this.logData.length;
      }
      this.logSetting.startIndex = 0;
      this.logSetting.minRowCount = value;
    },
  },
};
</script>

<style lang="scss">
.activity-icon {
  position: relative;
  top: calc(50% - 17px);
  font-size: 15px;
  margin-right: 10px;
}
.success-icon {
  color: #28b36d;
}
.warning-icon {
  color: #ffc107;
}
.danger-icon {
  color: #d43232;
}
.success-icon-bg {
  background: #28b36d33;
}
.warning-icon-bg {
  background: #ffc10733;
}
.danger-icon-bg {
  background: #d4323233;
}
.mw-60 {
  border-radius: 5px;
}
.log-row:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.card-header {
  height: 50px;
  background: #3f51b5;
  text-align: left;
  color: #fff;
  border-radius: 5px;
}
.log-detail {
  width: 100%;
  background-color: #dae0e5;
  padding: 6px 15px;
  border-radius: 5px;
  display: none;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
@media only screen and (max-width: 600px) {
  .custom-card-title {
    font-size: 16px !important;
  }
  .nav-pagination {
    float: none !important;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
