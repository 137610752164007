<template>
  <!-- Container -->
  <div class="row mt-2">
    <div class="col-12">
      <div
        class="log-detail mb-3"
        v-bind:class="`activity-log-${index}`"
        v-html="logDetail"
      ></div>
    </div>
  </div>

  <!-- ./Container -->
</template>

<script>
export default {
  name: "WeActivityLogDetail",
  props: ["logDetail", "index"],
};
</script>

<style lang="scss">
</style>
