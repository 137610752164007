<template>
  <!-- Container -->
  <div class="row">
    <div class="col-12 col-lg mb-2">
      <select class="custom-select">
        <option
          v-for="(item, index) in filter.timeList"
          v-bind:key="index"
          v-bind:value="item.value"
        >
          {{ `&#128336; ${item.name}` }}
        </option>
      </select>
    </div>
    <div class="col-12 col-lg mb-2">
      <select class="custom-select">
        <option value="-1">&#128373; Eylem Seç</option>
        <option
          v-for="(item, index) in filter.activityList"
          v-bind:key="index"
          v-bind:value="item.value"
        >
          {{ `${item.name}` }}
        </option>
      </select>
    </div>
    <div class="col-12 col-lg mb-2">
      <select class="custom-select">
        <option value="-1">&#129489; Çalışan Seç</option>
        <option value="0">Ahmet Pınar</option>
      </select>
    </div>
    <div class="col-12 col-lg mb-2">
      <button type="button" class="btn btn-primary w-100">
        <i class="fa fa-filter" aria-hidden="true"></i>
        Filtrele
      </button>
    </div>
    <div class="col-12 mt-3">
      <div class="form-group">
        <div class="form-group">
          <div class="input-group">
            <input
              autocomplete="off"
              type="text"
              placeholder="Ara"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./Container -->
</template>

<script>
export default {
  name: "WeActivityLogFilter",
  props: ["filter"],
};
</script>

<style lang="scss">
</style>
