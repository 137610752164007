<template>
  <!-- Container -->
  <div class="row mt-3">
    <div class="col-12 col-md-3 mb-2">
      <select class="custom-select" @change="onChangeMinRow($event)">
        <option value="3">3 Satır Görüntüle</option>
        <option value="5">5 Satır Görüntüle</option>
        <option value="10">10 Satır Görüntüle</option>
        <option value="20">15 Satır Görüntüle</option>
        <option value="30">30 Satır Görüntüle</option>
      </select>
    </div>
    <div class="col-12 col-md-9">
      <nav class="float-right nav-pagination">
        <ul class="pagination">
          <li
            class="page-item"
            v-bind:class="activePageID > 1 ? '' : 'disabled'"
          >
            <a
              class="page-link"
              v-on:click="
                changePage(activePageID - 1 > 0 ? activePageID - 1 : 1)
              "
              tabindex="-1"
              >Geri</a
            >
          </li>
          <li
            class="page-item"
            v-for="index in Math.ceil(rowCount / minRowCount)"
            v-bind:key="index"
            v-bind:class="activePageID == index ? 'active' : ''"
          >
            <a class="page-link" v-on:click="changePage(index)">{{ index }}</a>
          </li>
          <li
            class="page-item"
            v-bind:class="
              activePageID + 1 > Math.ceil(rowCount / minRowCount)
                ? 'disabled'
                : ''
            "
          >
            <a
              class="page-link"
              v-on:click="
                changePage(
                  activePageID + 1 > Math.ceil(rowCount / minRowCount)
                    ? activePageID
                    : activePageID + 1
                )
              "
              >İleri</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <!-- ./Container -->
</template>

<script>
export default {
  name: "WeActivityLogFooter",
  props: ["rowCount", "minRowCount"],
  data() {
    return {
      activePageID: 1,
    };
  },
  methods: {
    changePage(id) {
      this.activePageID = id;
      this.$emit("changePage", this.activePageID);
    },
    onChangeMinRow(event) {
      this.activePageID = 1;
      this.$emit("onChangeMinRow", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-link:hover {
  cursor: pointer;
}
</style>
