<template>
  <WeContainer header="ture" bread-crumb="" card="">
    <div class="card mb-3 sticky-top shadow-sm">
      <!----><!---->
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-12 col-md text-center text-md-left">
            <h5 class="mb-md-0">
              <i class="fa fa-address-card" aria-hidden="true"></i>
              {{ $t("userActivity") }}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3 sticky-top shadow-sm">
      <div class="container-fluid mb-5">
        <div class="page-content">
          <div class="container wide-container">
            <div class="row">
              <div class="col-12 mb-3 col-lg-12 mb-lg-0">
                <div lass="card mb-3">
                  <!-- activity log global row component -->
                  <WeActivityLog
                    v-bind:logData="logData"
                    v-bind:logSetting="logSetting"
                  />
                  <!-- -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </WeContainer>
</template>

<script>
import WeActivityLog from "../../../../../../components/layout/WeActivityLog/Index.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "ActivityList",
  components: {
    WeActivityLog,
  },
  data() {
    return {
      logData: [
        {
          personelName: "Ahmet Pınar",
          logTitle:
            "Trendyol pazar yeri içerisinde bulunan <a href='#'>Yayla Haşlanmış Aşurelik Buğday 250 Gr</a> ürünün açıklamasını, başlığını ve fiyatını güncelledi.",
          logDetail:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          logType: 2,
        },
        {
          personelName: "Ceren Yalçın",
          logTitle: "Ahmet adlı kullanıcıyı sildi.",
          logDetail:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          logType: 1,
        },
        {
          personelName: "Celal Şengör",
          logTitle:
            "Yayla Haşlanmış Maş Fasulyesi 400 Gr adında yeni bir ürün ekledi.",
          logDetail:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          logType: 3,
        },
        {
          personelName: "Ahmet Pınar2",
          logTitle:
            "Trendyol pazar yeri içerisinde bulunan Yayla Haşlanmış Aşurelik Buğday 250 Gr ürünün açıklamasını, başlığını ve fiyatını güncelledi.",
          logDetail:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          logType: 2,
        },
        {
          personelName: "Ceren Yalçın2",
          logTitle: "Ahmet adlı kullanıcıyı sildi.",
          logDetail:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          logType: 1,
        },
        {
          personelName: "Celal Şengör2",
          logTitle:
            "Yayla Haşlanmış Maş Fasulyesi 400 Gr adında yeni bir ürün ekledi.",
          logDetail:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          logType: 3,
        },
        {
          personelName: "Celal Şengör2",
          logTitle:
            "Yayla Haşlanmış Maş Fasulyesi 400 Gr adında yeni bir ürün ekledi.",
          logDetail:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          logType: 3,
        },
        {
          personelName: "Celal Şengör2",
          logTitle:
            "Yayla Haşlanmış Maş Fasulyesi 400 Gr adında yeni bir ürün ekledi.",
          logDetail:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          logType: 3,
        },
      ],
      logSetting: {
        minRowCount: 3,
        startIndex: 0,
        filter: {
          timeList: [
            { name: "Son 7 gün", value: 7 },
            { name: "Son 1 ay", value: 30 },
            { name: "Son 3 ay", value: 90 },
            { name: "Son 6 ay", value: 180 },
          ],
          activityList: [
            { name: "Ürün Ekleme / Silme", value: 1 },
            { name: "Müşteri Ekleme / Silme", value: 2 },
            { name: "Sipariş Ekleme / Silme", value: 3 },
            { name: "Kampanya Ekleme / Silme", value: 4 },
          ],
        },
      },
    };
  },
  methods: {
    onFilterClear() {
      this.copyFilter = {};
      helper.clearItem(this.filter);
      this.updateMemberList();
    },
    onSearch() {
      this.copyFilter = helper.clone(this.filter);
      this.$refs.memberTable.currentPage = 1;
      this.updateMemberList();
    },
  },
  computed: {
    /*...mapState({
      member: (state) => state.member,
      memberGroups: (state) => state.membergroup.list,
    }),*/
  },
  mounted() {},
};
</script>

<style scoped>
</style>